<template>
  <div class="d-flex flex-column justify-content-between" :class="{'h-100': fullHeight}">
    <div class="overflow-auto">
      <b-table
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :select-mode="selectMode"
          show-empty
          :selectable="selectable"
          table-class="text-nowrap"
          :tbody-tr-class="rowClass"
          @row-selected="onRowSelected"
      >
        <slot v-for="(_, name) in $slots" :name="name" :slot="name"/>
        <template
            v-for="(_, name) in $scopedSlots"
            :slot="name"
            slot-scope="slotData"
        >
          <slot :name="name" v-bind="slotData"/>
        </template>
      </b-table>
    </div>
    <app-pagination v-if="!hidePagination" :meta="meta" @pageChange="currentPage = $event"/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import AppPagination from './components/AppPagination';

export default {
  components: {
    AppPagination
  },
  props: {
    fields: {
      default: null
    },
    filters: {
      default: null
    },
    moduleName: {
      type: String,
      default: '',
    },
    requestUrl: {
      type: String,
      default: '',
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    selectMode: {
      type: String,
      default: 'single'
    },
    hidePagination: {
      type: Boolean,
      default: false
    },
    updateData: {
      type: Boolean,
      default: false
    },
    rowClass: {
      default: ''
    },
    fullHeight: {
      default: true
    },
  },
  data: () => ({
    currentPage: 1,
    isLoading: false
  }),
  beforeMount() {
    this.getData();
  },
  computed: {
    ...mapGetters({
      items: 'tableStore/ITEMS',
      meta: 'tableStore/META'
    })
  },
  watch: {
    currentPage(val) {
      if (val) {
        this.getData();
      }
    },
    updateData(val) {
      if (val) {
        this.getData();
      }
    },
    filters() {
      this.getData();
    }
  },
  methods: {
    onRowSelected(event) {
      this.$emit('select', event[0]);
    },
    async getData() {
      this.isLoading = true;
      await this.$store.dispatch('tableStore/GET_ITEMS', {
        requestUrl: this.requestUrl,
        filters: this.filters,
        page: this.currentPage
      });
    },
  },
  beforeDestroy() {
    this.$store.commit('tableStore/CLEAR_ITEMS');
  }
}
</script>

<style lang="scss" scoped>
.table::v-deep {
  td[role="cell"] {
    vertical-align: middle;
  }
}
</style>